import React from 'react';
import './Home.css'
import FITC from '../assets/FITC1.jpg';
import DelyBites from '../assets/delybites.png';
import FoodCognics from '../assets/client3.png';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ImQuotesLeft, ImQuotesRight } from "react-icons/im";

function Testimonial() {
  const testimonialsData = [
    {
      id: 1,
      author: "Neha Rajendra Tak",
      company: "FITC",
      image: FITC,
      content: "Working with TechCognics India Pvt. Ltd. exceeded our expectations and truly stands out in the competitive landscape. Their team's expertise and commitment to delivering a tailored ERP solution streamlined our business processes, enhancing efficiency across departments. The robust software has significantly contributed to our growth. Highly recommended for their professionalism and innovative solutions.",
    },
    {
      id: 2,
      author: "",
      company: "DelyBites Foods",
      image: DelyBites,
      content: "We are thankful to TechCognics for helping our online presence skyrocket. Their strategic approach to digital advertising not only increased our brand visibility but also generated a substantial uptick in leads. The targeted campaigns were a game-changer, delivering measurable results. Trustworthy, creative, and ROI-focused – we're thrilled with the impact on our business. We highly recommend TechCognics' services to anyone seeking reliable and innovative software development partners.",
    },
    {
      id: 3,
      author: "",
      company: "FoodCognics India Private Limited",
      image: FoodCognics,
      content: "We had an amazing experience working with TechCognics. Their team demonstrated extraordinary abilities, meticulous attention to detail, and dedication to producing excellent outcomes. Collaboration was seamless, and the final result accurately showcases their skills. We wholeheartedly endorse their services to anyone looking for a creative and dependable web development partner.",
    }];
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <div>
      <div className="row">
        <div data-aos="fade-up"
          className="col-lg-12 text-center appear-animation animated appear-animation-visible"
          data-appear-animation="fadeInUpShorter">
          <h2 className="uppercase test_text">
            Client Testimonials
          </h2>

        </div>
      </div>
      <div
        id="testimonial_section"
        className="custom-section-padding staff-augmentation-testimonial py-5">
        <ImQuotesLeft className='quotesImg' />
        <div className="container">
          <div className="row">
            <div className="col">
              <Slider {...settings}>
                {testimonialsData.map((testimonial) => (
                  <div key={testimonial.id}>
                    <div className="row">
                      <div className="col-8 col-sm-4 col-lg-2 text-center pt-5 image">
                        <img
                          className="img-fluid"
                          src={testimonial.image}
                          width="166"
                          height="160"
                          alt="testimonial_img"
                          loading="lazy"
                        />
                      </div>
                      <div className="col-12 col-sm-12 col-lg-10">
                        <div className="testimonial custom-testimonial-style-1 mb-0">
                          <blockquote className="pb-2">
                            <p>{testimonial.content}</p>
                          </blockquote>
                          <div className="testimonial-author float-start">
                            <p>
                              <strong>{testimonial.author} - </strong>
                              <strong><span className="text-color-primary">
                                {testimonial.company}
                              </span></strong>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
        <ImQuotesRight className='quotes' />
      </div>
    </div>
  )
}

export default Testimonial;