import React from 'react'
import { faEnvelope, faSquarePhone } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './ContactSidebar.css';

function ContactSidebar() {
    return (
        <div className='contact_sidebar'>
            <aside className="compact-nav fixed group overflow-hidden right-0 top-[calc(9rem)] z-1000  w-[calc(3rem)] border-r border-gray-300/40 bg-blue-500 dark:border-gray-700 hover:w-60 hover:shadow-2xl">
                <div className="flex flex-col justify-between">
                    <div className='mt-2 bg-blue-500' >
                        <ul className="px-1 -ml-px font-medium tracking-wide">
                            <li className="w-max space-y-4 group-hover:w-full px-3 py-2 hover:bg-blue-600">
                                <a href="tel:9890452720" className='sidebar_links'>
                                    <div className='flex items-center gap-4 text-white'>
                                        <FontAwesomeIcon size='lg' icon={faSquarePhone} />
                                        <span>
                                            +91 9890452720
                                        </span>
                                    </div>
                                </a>
                            </li>
                            <li className="w-max space-y-4 group-hover:w-full px-3 py-2 hover:bg-blue-600">
                                <a href="mailto:sales@techcognicsindia.com" className='sidebar_links'>
                                    <div className='flex items-center gap-4 text-white'>
                                        <FontAwesomeIcon size='lg' icon={faEnvelope} />
                                        <span>
                                            Email
                                        </span>
                                    </div>
                                </a>
                            </li>
                            <li className="w-max space-y-4 group-hover:w-full px-3 py-2 hover:bg-blue-600">
                                <a href="https://www.linkedin.com/company/techcognics-pvt-ltd" className='sidebar_links'>
                                    <div className='flex items-center gap-4 text-white'>
                                        <FontAwesomeIcon size='lg' icon={faLinkedin} />
                                        <span>
                                            LinkedIn
                                        </span>
                                    </div>
                                </a>
                            </li>
                            <li className="w-max space-y-4 group-hover:w-full px-3 py-2 hover:bg-blue-600">
                                <a href="https://www.facebook.com/profile.php?id=61550502891553&mibextid=ZbWKwL" className='sidebar_links'>
                                    <div className='flex items-center gap-4 text-white'>
                                        <FontAwesomeIcon size='lg' icon={faFacebook} />
                                        <span>
                                            Facebook
                                        </span>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </aside>
        </div>
    )
}

export default ContactSidebar