import React from "react";
import "./ServiceComp.css";
import { Link } from "react-router-dom";
import img1 from "../../assets/img/image1.webp";
import img2 from "../../assets/img/app-development.avif";
import img3 from "../../assets/img/web-programming.jpg";

const services = [
  {
    title: "IT Consulting Services",
    description: "We are convinced that nothing we do is more important than hiring and developing people. At the end of the day, you bet on people, not on strategies.",
    image: img1,
    icon: "bi bi-bounding-box-circles"
  },
  {
    title: "App Development",
    description: "We Build Mobile Apps that ignite your business. We will help you reach international audiences with our mobile Apps, compatible with all OS.",
    image: img2,
    icon: "bi bi-phone-vibrate"
  },
  {
    title: "Web Development",
    description: "Are you looking to take your business online? Let our developer help build your digital footprint by creating a top-notch company website.",
    image: img3,
    icon: "bi bi-pc-display-horizontal"
  }
];

const ServiceComponents = () => (
  <section className="comp_ser">
    <div className="container" data-aos="fade-up">
      <div className="heads-title">
        <span>
          <h2><b>SERVICES</b></h2>
        </span>
        <p className="para_serv">Expect nothing less than perfect. We are associated with the best private and public sector companies, dealing in consultant, freelance, and permanent roles. TechCognics has the strength of avant-garde tools and a team of top-notch software developers to deliver exceptional web application development services with powerful features.</p>
      </div>
      <div className="row gy-5">
        {services.map((service, index) => (
          <div key={index} className="col-xl-4 col-md-4" data-aos="zoom-in" data-aos-delay={500 + 100 * index}>
            <div className="home-service">
              <div className="img">
                <img src={service.image} className="img-fluid" alt={service.title} />
              </div>
              <div className="home-details position-relative">
                <div className="icon">
                  <i className={service.icon}></i>
                </div>
                <h3>{service.title}</h3>
                <p>{service.description}</p>
                <Link to="/services">
                  <button className='button button-2'>Know More</button>
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </section>
);

export default ServiceComponents;