import React, { Suspense, lazy } from 'react';
import './Home.css'
import ServiceComp from './Components/ServiceComp';
import TechComp from './Components/TechComp';
import Footer from './Components/Footer';
import Customers from './Components/Customers';
import ContactSidebar from './ContactSidebar';
import Testimonial from './testimonials';
import HomeCarousel from './Components/HomeCarousel';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PopUpModal1 from './Modal/popUpButton';

const LandingCarousel = lazy(() => import('./Carousel/LandingCarousel'))
function Home() {
  return (
    <div className="home">
      <Suspense fallback={
        <div className='carousel_fallback'>
          <span className='callback_msg'>welcome...</span>
        </div>
      }>
        <PopUpModal1 />
        <LandingCarousel />
      </Suspense>
      <ContactSidebar />
      <ServiceComp />
      <HomeCarousel />
      <TechComp />
      <Customers />
      <Testimonial />
      <Footer />
    </div>
  )
}

export default Home