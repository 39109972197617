import React, { Suspense, lazy, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Header from "./header/Header";
import Home from "./Home/Home";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import ReactGA from "react-ga4";
import ScrollToTop from "./header/scrollToTop";

const Client = lazy(() => import("./header/NavOptions/Client"));
const Consulting = lazy(() => import("./header/NavOptions/Consulting"));
const Technologies = lazy(() => import("./header/NavOptions/Technologies"));
const Services = lazy(() => import("./header/NavOptions/Services"));
const Services1 = lazy(() => import("./header/NavOptions/services1"));
const Services2 = lazy(() => import("./header/NavOptions/service2"));
const Services3 = lazy(() => import("./header/NavOptions/services3"));
const HiringPage1 = lazy(() => import("./header/NavOptions/hiringPage.js"));
const WebDesign = lazy(() => import("./header/NavOptions/webDesigns.js"));
const WebDevCompany = lazy(() => import("./header/NavOptions/webDevCompany.js"));
const Contact = lazy(() => import("./header/NavOptions/Contact"));
const About = lazy(() => import("./header/NavOptions/About"));
const Thank = lazy(() => import("./Routing/service/thank.js"));
const Privacy = lazy(() => import("./header/NavOptions/privacy1.js"));

ReactGA.initialize('AW-11319131363');
function App() {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "Home Page",
    });
  }, []);

  return (
    <BrowserRouter>
      <div className="App">
        <Suspense fallback={<div>loading...</div>}>
          <Header />

          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/client" element={<Client />} />
            <Route path="/consulting" element={<Consulting />} />
            <Route path="/technologies" element={<Technologies />} />
            <Route path="/services" element={<Services />} />
            <Route path="/website-development" element={<Services1 />} />
            <Route
              path="/privacy-policy"
              element={<Privacy />}
            />
            <Route
              path="/mobile-app-development-company"
              element={<Services2 />}
            />
            <Route
              path="/ERP-implementation-services"
              element={<Services3 />}
            />
            <Route path="/websitedevelopment" element={<Services1 />} />
            <Route
              path="/mobileapp-development-company"
              element={<Services2 />}
            />
            <Route
              path="/outsourcing-web-development"
              element={<HiringPage1 />}
            />
            <Route
              path="/outsourcingweb-development"
              element={<HiringPage1 />}
            />
            <Route
              path="/web-design-development"
              element={<WebDesign />}
            />
            <Route
              path="/web-development-company"
              element={<WebDevCompany />}
            />
            <Route path="/ERPimplementation-services" element={<Services3 />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about" element={<About />} />
            <Route path="/thankyou" element={<Thank />} />
            <Route path='/ERP-implementation-services/thankyou' element={<Thank />} />
            <Route path='/mobile-app-development-company/thankyou' element={<Thank />} />
            <Route path='/website-development/thankyou' element={<Thank />} />
            <Route path='/ERPimplementation-services/thankyou' element={<Thank />} />
            <Route path='/mobileapp-development-company/thankyou' element={<Thank />} />
            <Route path='/websitedevelopment/thankyou' element={<Thank />} />
            <Route path='/outsourcing-web-development/thankyou' element={<Thank />} />
            <Route path='/outsourcingweb-development/thankyou' element={<Thank />} />
            <Route path='/contact/thankyou' element={<Thank />} />
            <Route path='/web-design-development/thankyou' element={<Thank />} />
            <Route path='/web-development-company/thankyou' element={<Thank />} />
          </Routes>
        </Suspense>
      </div>

    </BrowserRouter>
  );
}

export default App;
