import React from 'react'
import './Customers.css'
import img1 from '../../assets/client1.png'
import img2 from '../../assets/client2.jpg'
import img3 from '../../assets/client3.png'
import img4 from '../../assets/client4.jpg'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

function Customers() {

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <Carousel className='customer_carousel'
      responsive={responsive}
      itemClass="carousel-item-padding-100-px"
      autoPlaySpeed={1000}
      infinite={true}
    >
      <div className='row1' data-aos="flip-left" data-aos-duration="1000">
        <img className='customer' src={img1} alt="customer1" />
      </div>
      <div className='row1' data-aos="flip-left" data-aos-duration="1000">
        <img className='customer' src={img2} alt="customer2" />
      </div>
      <div className='row1' data-aos="flip-right" data-aos-duration="1000">
        <img className='customer' src={img3} alt="customer3" />
      </div>
      <div className='row1' data-aos="flip-right" data-aos-duration="1000">
        <img className='customer' src={img4} alt="customer4" />
      </div>

    </Carousel>
  )
}

export default Customers